import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const Contact = () => {
return (
    <Layout>
    <Head title='contact'/>
        <h1>Reach out!</h1>
        <p><a href="https://twitter.com/NFTycats" target="_blank">@NFTycats</a></p>
    </Layout>
)
}

export default Contact